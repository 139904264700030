import React from 'react';
import { Link } from 'react-router-dom';
import './Bottom.css';
import { useTranslation } from 'react-i18next'; // Import useTranslation

const Bottom = () => {
  const { t } = useTranslation(); // Initialize useTranslation

  return (
    <div className="bottom">
      <div className="bottom-content">
        <p>{t('bottom.copyright')}</p>
        <p><Link to="/tietosuojaseloste">{t('bottom.privacy_policy')}</Link></p>
        <p>{t('bottom.company_id')}</p>
      </div>
    </div>
  );
};

export default Bottom;

// PricingCalculator.js

const materialBasePrice = 45;

const rungTypePrices = {
  '/models/puola.obj': 19,
  '/models/puola2.obj': 32
};

const selkasuojaBasePrice = 269;
const kiinnityslevyBasePrice = 35;

const materialMultipliers = {
  anodizedAluminium: 0.85,
  stainless: 1,
  galvanized: 0.62,
  aluminium: 0.74
};

const materialCodes = {
  anodizedAluminium: 'AA',
  stainless: 'SS',
  galvanized: 'GA',
  aluminium: 'AL'
};

export function calculatePrice(config) {
  const { material, count, fallProtection, dimensions, rungType, kiinnityslevyPositions } = config;
  const selkasuojaCount = Math.floor((dimensions.height - 4000) / 1070) + 2; // Lasketaan selkäsuojien määrä
  const materialMultiplier = materialMultipliers[material] || 1;
  const materialPrice = materialBasePrice * materialMultiplier;
  const rungTypePrice = rungTypePrices[rungType] || 0;
  const kiinnityslevyCount = kiinnityslevyPositions.length;

  const price = (materialPrice * dimensions.height / 1000) + 
                (rungTypePrice * count * materialMultiplier) + 
                (fallProtection ? selkasuojaCount * selkasuojaBasePrice * materialMultiplier : 0) + // Selkäsuojien määrä ja materiaali vaikuttavat hintaan vain, jos fall protection on päällä
                (kiinnityslevyCount * kiinnityslevyBasePrice * materialMultiplier); // Lisätään kiinnityslevyjen hinta

  return Math.round(price * 100) / 100; // Pyöristetään hinta kahden desimaalin tarkkuudella
}

export function generateProductCode(config) {
  const { material, count, fallProtection, dimensions, rungType, kiinnityslevyPositions } = config;
  const selkasuojaCount = Math.floor((dimensions.height - 4000) / 1070) + 2; // Lasketaan selkäsuojien määrä
  const kiinnityslevyCount = kiinnityslevyPositions.length;

  const materialCode = materialCodes[material] || 'XX'; // Käytetään tarkempia koodeja materiaaleille
  const rungTypeCode = rungType === '/models/puola.obj' ? 'P' : 'A';
  const fallProtectionCode = fallProtection ? 'F' : 'N';

  // Poistetaan selkasuojaCount, jos fall protection on 'N'
  const selkasuojaPart = fallProtection ? `-${selkasuojaCount}` : '';

  return `${materialCode}-${dimensions.height}-${count}-${rungTypeCode}-${fallProtectionCode}${selkasuojaPart}-${kiinnityslevyCount}`;
}

import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import './3DPrinting.css';
import demoImage from '../../images/3d-tulostus_render.jpg';
import { useTranslation } from 'react-i18next';

const ThreeDPrinting = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { t } = useTranslation();
  const [isImageOpen, setIsImageOpen] = useState(false);
  const [isFading, setIsFading] = useState(false);

  useEffect(() => {
    const page = document.querySelector('.three-d-printing');
    if (page) {
      const handleRouteChange = () => {
        page.classList.add('fade-out');
        setTimeout(() => {
          navigate(location.pathname, { replace: true });
        }, 500); // Varmistaa että efekti päättyy ennen siirtymistä
      };

      // Kuunnellaan linkkien klikkauksia ja estetään niiden oletustoiminta
      const links = document.querySelectorAll('a');
      links.forEach(link => {
        link.addEventListener('click', (event) => {
          event.preventDefault();
          handleRouteChange();
          setTimeout(() => {
            navigate(link.getAttribute('href'), { replace: true });
          }, 500); // Varmistaa että efekti päättyy ennen siirtymistä
        });
      });

      return () => {
        links.forEach(link => {
          link.removeEventListener('click', handleRouteChange);
        });
      };
    }
  }, [navigate, location.pathname]);

  const handleImageClick = () => {
    setIsImageOpen(true);
  };

  const handleCloseImage = () => {
    setIsFading(true);
    setTimeout(() => {
      setIsImageOpen(false);
      setIsFading(false);
    }, 500); // Odotetaan, että fade-out-efekti päättyy
  };

  return (
    <div className="three-d-printing">
      {isImageOpen && (
        <div
          className={`image-overlay ${isFading ? 'fade-out' : 'fade-in'}`}
          onClick={handleCloseImage}
        >
          <img className="expanded-image" src={demoImage} alt={t('3dprinting.image_alt')} />
        </div>
      )}
      <div className="navigation-arrows">
        <Link to="/suunnittelun-automatisointi" className="left-arrow">
          〈
          <span className="hover-text left-hover">{t('3dprinting.left_arrow')}</span>
        </Link>
        <h1 className="main-title">{t('3dprinting.title')}</h1>
        <Link to="/mekaniikkasuunnittelu" className="right-arrow">
          〉
          <span className="hover-text right-hover">{t('3dprinting.right_arrow')}</span>
        </Link>
      </div>
      <hr className="section-divider" />
      <p>{t('3dprinting.paragraph1')}</p>
      <p>{t('3dprinting.paragraph2')}</p>
      <p>{t('3dprinting.paragraph3')}</p>
      <hr className="section-divider" />
      <div className="image-wrapper">
        <img
          className="three-d-image"
          src={demoImage}
          alt={t('3dprinting.image_alt')}
          onClick={handleImageClick}
        />
        <div className="gradient-overlay"></div>
      </div>
    </div>
  );
};

export default ThreeDPrinting;

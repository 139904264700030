import React, { useEffect } from 'react';
import './References.css';

const References = () => {

  useEffect(() => {
    const page = document.querySelector('.references');
    if (page) {
      const handleRouteChange = () => {
        page.classList.add('fade-out');
        setTimeout(() => {
          page.classList.remove('fade-out');
        }, 1000); // Varmistaa että efekti päättyy ennen siirtymistä
      };

      // Kuunnellaan linkkien klikkauksia ja estetään niiden oletustoiminta
      const links = document.querySelectorAll('a');
      links.forEach(link => {
        link.addEventListener('click', (event) => {
          handleRouteChange();
        });
      });

      return () => {
        links.forEach(link => {
          link.removeEventListener('click', handleRouteChange);
        });
      };
    }
  }, []);

  return (
    <div className="references">
      <div className="references-grid">
        <div className="reference-item">
          <a href="https://www.entop.fi/" target="_blank" rel="noopener noreferrer">
            <img src={`${process.env.PUBLIC_URL}/images/references/entop.png`} alt="Entop" />
          </a>
        </div>
        <div className="reference-item">
          <a href="https://reminet.fi/" target="_blank" rel="noopener noreferrer">
            <img src={`${process.env.PUBLIC_URL}/images/references/reminet.png`} alt="Reminet" />
          </a>
        </div>
        <div className="reference-item">
          <a href="https://www.tkmgroup.com/fi/tkm-ttt-finland" target="_blank" rel="noopener noreferrer">
            <img src={`${process.env.PUBLIC_URL}/images/references/tkm-ttt.png`} alt="TKM TTT Finland" />
          </a>
        </div>
        <div className="reference-item">
          <a href="https://oilon.com/fi/" target="_blank" rel="noopener noreferrer">
            <img src={`${process.env.PUBLIC_URL}/images/references/oilon.png`} alt="Oilon" />
          </a>
        </div>
        <div className="reference-item">
          <a href="https://surfacetreatment.johncockerill.com/about-us/galvatek/" target="_blank" rel="noopener noreferrer">
            <img src={`${process.env.PUBLIC_URL}/images/references/john-cockerill.png`} alt="John Cockerill - Galvatek" />
          </a>
        </div>
      </div>
    </div>
  );
};

export default References;

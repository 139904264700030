import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import './Banner.css';

const Banner = () => {
  const [showBanner, setShowBanner] = useState(false);
  const { t } = useTranslation(); // Hook for translation

  useEffect(() => {
    const acceptedCookies = sessionStorage.getItem('acceptedCookies');
    if (!acceptedCookies) {
      setShowBanner(true);
      console.log('Cookies accepted = 0');
    } else {
      console.log('Cookies accepted = 1');
    }
  }, []);

  const handleAccept = () => {
    sessionStorage.setItem('acceptedCookies', 'true');
    setShowBanner(false);
    document.body.classList.remove('cookies-accepted-0');
    console.log('Cookies accepted = 1');
  };

  return (
    showBanner && (
      <div className="cookie-banner">
        <p>
          {t('banner.message')}{' '}
          <Link to="/tietosuojaseloste">{t('banner.read_more')}</Link>
        </p>
        <button onClick={handleAccept}>{t('banner.accept')}</button>
      </div>
    )
  );
};

export default Banner;

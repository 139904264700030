import React from 'react';
import { useTranslation } from 'react-i18next';
import './PrivacyPolicy.css';

const PrivacyPolicy = () => {
  const { t } = useTranslation();

  return (
    <div className="privacy-policy">
      <h1>{t('privacyPolicy.title')}</h1>

      <h2>{t('privacyPolicy.section1Title')}</h2>
      <p><strong>{t('privacyPolicy.company')}</strong><br />
      {t('privacyPolicy.companyID')}<br />
      {t('privacyPolicy.address')}<br />
      {t('privacyPolicy.email')}</p>

      <h2>{t('privacyPolicy.section2Title')}</h2>
      <p>{t('privacyPolicy.dataCollectionDescription')}<br />
      - {t('privacyPolicy.dataCollectionItem1')}</p>

      <h2>{t('privacyPolicy.section3Title')}</h2>
      <p>{t('privacyPolicy.dataProcessingDescription')}<br />
      - {t('privacyPolicy.dataProcessingItem1')}<br />
      - {t('privacyPolicy.dataProcessingItem2')}</p>

      <p>{t('privacyPolicy.dataCollectionMethodsDescription')}<br />
      - {t('privacyPolicy.dataCollectionMethod1')}<br />
      - {t('privacyPolicy.dataCollectionMethod2')}</p>

      <h2>{t('privacyPolicy.section4Title')}</h2>
      <p>{t('privacyPolicy.dataRetentionDescription')}</p>

      <h2>{t('privacyPolicy.section5Title')}</h2>
      <p>{t('privacyPolicy.userRightsDescription')}<br />
      - {t('privacyPolicy.userRightsItem1')}<br />
      - {t('privacyPolicy.userRightsItem2')}<br />
      - {t('privacyPolicy.userRightsItem3')}</p>

      <p>{t('privacyPolicy.userRightsContact')}</p>

      <h2>{t('privacyPolicy.section6Title')}</h2>
      <p>{t('privacyPolicy.cookiesDescription')}</p>

      <p>{t('privacyPolicy.cookiesUsageDescription')}</p>

      <h2>{t('privacyPolicy.section7Title')}</h2>
      <p>{t('privacyPolicy.thirdPartiesDescription')}</p>

      <h2>{t('privacyPolicy.contactInfoTitle')}</h2>
      <p>{t('privacyPolicy.contactInfoDescription')}<br />
      - {t('privacyPolicy.contactEmail')}<br />
      - {t('privacyPolicy.contactPhone')}</p>
    </div>
  );
};

export default PrivacyPolicy;

import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import './Services.css';
import mekanikkasuunnittelu from '../../images/mekaniikkasuunnittelu.png';
import suunnittelunAutomatisointi from '../../images/suunnittelun_automatisointi.png';
import tulostus3D from '../../images/3d-tulostus.png';

const trackEvent = (action, category, label) => {
  if (window.gtag) {
    window.gtag('event', action, {
      event_category: category,
      event_label: label,
    });
  }
};

const Services = () => {
  const { t } = useTranslation();

  return (
    <div className="services">
      <div className="service-item">
        <Link to="/mekaniikkasuunnittelu" onClick={() => trackEvent('click', 'service', 'mekaniikkasuunnittelu')}>
          <img src={mekanikkasuunnittelu} alt={t('services.mekaniikkasuunnittelu')} className="service-logo" />
        </Link>
        <p>{t('services.mekaniikkasuunnittelu')}</p>
      </div>
      <div className="service-item automatisointi">
        <Link to="/suunnittelun-automatisointi" onClick={() => trackEvent('click', 'service', 'suunnittelun automatisointi')}>
          <img src={suunnittelunAutomatisointi} alt={t('services.suunnittelun_automatisointi')} className="service-logo" />
        </Link>
        <p>{t('services.suunnittelun_automatisointi')}</p>
      </div>
      <div className="service-item">
        <Link to="/3d-tulostus" onClick={() => trackEvent('click', 'service', '3d-tulostus')}>
          <img src={tulostus3D} alt={t('services.3d_tulostus')} className="service-logo" />
        </Link>
        <p>{t('services.3d_tulostus')}</p>
      </div>
    </div>
  );
};

export default Services;

import React, { useEffect, useState, useRef } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import './MechanicalDesign.css';
import demoVideo from '../../images/mekaniikkasuunnittelu_demo.m4v';

const MechanicalDesign = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const [isVideoOpen, setIsVideoOpen] = useState(false);
  const [isFading, setIsFading] = useState(false);
  const videoRef = useRef(null);
  const { t } = useTranslation();

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    const page = document.querySelector('.mechanical-design');
    if (page) {
      const handleRouteChange = () => {
        page.classList.add('fade-out');
        setTimeout(() => {
          navigate(location.pathname, { replace: true });
        }, 500); // Varmistaa että efekti päättyy ennen siirtymistä
      };

      // Kuunnellaan linkkien klikkauksia ja estetään niiden oletustoiminta
      const links = document.querySelectorAll('a');
      links.forEach(link => {
        link.addEventListener('click', (event) => {
          event.preventDefault();
          handleRouteChange();
          setTimeout(() => {
            navigate(link.getAttribute('href'), { replace: true });
          }, 500); // Varmistaa että efekti päättyy ennen siirtymistä
        });
      });

      return () => {
        links.forEach(link => {
          link.removeEventListener('click', handleRouteChange);
        });
      };
    }
  }, [navigate, location.pathname]);

  const handleVideoClick = () => {
    setIsVideoOpen(true);
  };

  const handleCloseVideo = () => {
    setIsFading(true);
    setTimeout(() => {
      setIsVideoOpen(false);
      setIsFading(false);
    }, 500); // Odotetaan, että fade-out-efekti päättyy
  };

  return (
    <div className="mechanical-design">
      {isVideoOpen && (
        <div
          className={`video-overlay ${isFading ? 'fade-out' : 'fade-in'}`}
          onClick={handleCloseVideo}
        >
          <video ref={videoRef} className="expanded-video" autoPlay muted loop playsInline controls>
            <source src={demoVideo} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </div>
      )}
      <div className="navigation-arrows">
        <Link to="/3d-tulostus" className="left-arrow">
          〈
          <span className="hover-text left-hover">{t('mechanicalDesign.leftArrow')}</span>
        </Link>
        <h1 className="main-title">
          {isMobile ? (
            <>
              {t('mechanicalDesign.titleMobile')}
            </>
          ) : (
            t('mechanicalDesign.title')
          )}
        </h1>
        <Link to="/suunnittelun-automatisointi" className="right-arrow">
          〉
          <span className="hover-text right-hover">{t('mechanicalDesign.rightArrow')}</span>
        </Link>
      </div>
      <hr className="section-divider" />
      <p>{t('mechanicalDesign.paragraph1')}</p>
      <p>{t('mechanicalDesign.paragraph2')}</p>
      <p>{t('mechanicalDesign.paragraph3')}</p>
      <hr className="section-divider" />
      <div className="video-wrapper">
        <video
          ref={videoRef}
          className="mechanical-video"
          width="480"
          autoPlay
          muted
          loop 
          playsInline
          onClick={handleVideoClick}
        >
          <source src={demoVideo} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      </div>
    </div>
  );
};

export default MechanicalDesign;

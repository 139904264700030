import React from 'react';
import './About.css';
import { Trans, useTranslation } from 'react-i18next';
import EinoImage from '../../images/Eino_Itkonen-300x300.jpg';
import UulaImage from '../../images/Uula_Hursti-300x300.jpg';
import RikuImage from '../../images/Riku_Rainevuo-800x800.jpg';

const About = () => {
  const { t } = useTranslation();

  return (
    <div className="about-container">
      <h1 className="about-title" style={{ color: 'var(--main-color)' }}>{t('about.title')}</h1>
      <p className="about-description">
        <Trans i18nKey="about.description">
          tarjoaa laajan kattauksen konesuunnittelun ja konsultoinnin insinööriosaamista.<br /><br />Autamme asiakkaitamme säästämään merkittävästi aikaa<br />automaattisoimalla suunnittelun, tuotannon ja myynnin prosesseja.<br /><br /><br />
        </Trans>
      </p>
      <div className="owners">
        <div className="owner">
          <img src={EinoImage} alt={t('about.eino.name')} className="owner-image" />
          <p className="owner-info">
            <strong className="owner-name">{t('about.eino.name')}</strong><br />
            {t('about.eino.title1')}<br />
            {t('about.eino.title2')}<br />
            {t('about.eino.email')}<br />
            {t('about.eino.phone')}
          </p>
        </div>
        <div className="owner">
          <img src={UulaImage} alt={t('about.uula.name')} className="owner-image" />
          <p className="owner-info">
            <strong className="owner-name">{t('about.uula.name')}</strong><br />
            {t('about.uula.title1')}<br />
            {t('about.uula.title2')}<br />
            {t('about.uula.email')}<br />
            {t('about.uula.phone')}
          </p>
        </div>
        <div className="owner">
          <img src={RikuImage} alt={t('about.riku.name')} className="owner-image" />
          <p className="owner-info">
            <strong className="owner-name">{t('about.riku.name')}</strong><br />
            {t('about.riku.title1')}<br />
            {t('about.riku.email')}<br />
            {t('about.riku.phone')}
          </p>
        </div>
      </div>
    </div>
  );
};

export default About;

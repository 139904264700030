import React, { useRef, useState, useEffect } from 'react';
import { Canvas, useLoader } from '@react-three/fiber';
import { OrbitControls } from '@react-three/drei';
import { OBJLoader } from 'three/examples/jsm/loaders/OBJLoader';
import { MeshStandardMaterial } from 'three';
import './Configurator.css';
import { calculatePrice, generateProductCode } from './PricingCalculator'; // Importataan hinnoittelulaskuri ja tuotekoodin generaattori
import Tikas from './Tikas';
import Tikas2 from './Tikas2';

const STEP = 300; // Määritetään step vakiona
export const SELKASUOJA_HEIGHT = 1070; // Selkäsuojan korkeus

function Pystyputki({ scale, material }) {
  const obj = useLoader(OBJLoader, '/models/pystyputki.obj');
  const objRef = useRef();

  useEffect(() => {
    if (objRef.current) {
      // Aseta materiaalit
      objRef.current.traverse((child) => {
        if (child.isMesh) child.material = material;
      });

      // Aseta mittasuhteet
      objRef.current.scale.set(0.1, scale.height / 4500 * 0.1, 0.1);

      // Sijoita malli
      objRef.current.position.set(0, -window.innerHeight / 4, 0); // Siirretään puoli ruutua alaspäin
    }
  }, [scale, material]);

  return <primitive ref={objRef} object={obj.clone()} />;
}

function Puola({ material, count, model }) {
  const obj = useLoader(OBJLoader, model);
  const objRefs = useRef([]);

  useEffect(() => {
    objRefs.current.forEach((objRef, index) => {
      if (objRef) {
        // Aseta materiaalit
        objRef.traverse((child) => {
          if (child.isMesh) child.material = material;
        });

        // Aseta mittasuhteet
        objRef.scale.set(0.1, 0.1, 0.1);

        // Sijoita mallit Y-akselilla
        objRef.position.set(0, -window.innerHeight / 4 + index * (STEP / 10), 0); // Siirretään puoli ruutua alaspäin ja sijoitetaan Y-akselilla
      }
    });
  }, [material, count]);

  return (
    <>
      {Array.from({ length: count }).map((_, i) => (
        <primitive key={i} ref={(el) => (objRefs.current[i] = el)} object={obj.clone()} />
      ))}
    </>
  );
}

function Selkasuoja({ material, visible, position }) {
  const obj = useLoader(OBJLoader, '/models/selkasuoja.obj');
  const objRef = useRef();

  useEffect(() => {
    if (objRef.current) {
      // Aseta materiaalit
      objRef.current.traverse((child) => {
        if (child.isMesh) child.material = material;
      });

      // Aseta mittasuhteet
      objRef.current.scale.set(0.1, 0.1, 0.1);

      // Sijoita malli
      objRef.current.position.set(0, position, 0); // Siirretään puoli ruutua alaspäin ja sijoitetaan Y-akselilla
    }
  }, [material, position]);

  return visible ? <primitive ref={objRef} object={obj.clone()} /> : null;
}

function Kiinnityslevy({ material, positions }) {
  const obj = useLoader(OBJLoader, '/models/kiinnityslevy.obj');
  const objRefs = useRef([]);

  useEffect(() => {
    objRefs.current.forEach((objRef, index) => {
      if (objRef) {
        // Aseta materiaalit
        objRef.traverse((child) => {
          if (child.isMesh) child.material = material;
        });

        // Aseta mittasuhteet
        objRef.scale.set(0.1, 0.1, 0.1);

        // Sijoita mallit Y-akselilla
        objRef.position.set(0, positions[index], 0); // Sijoitetaan Y-akselilla
      }
    });
  }, [material, positions]);

  return (
    <>
      {positions.map((_, i) => (
        <primitive key={i} ref={(el) => (objRefs.current[i] = el)} object={obj.clone()} />
      ))}
    </>
  );
}

function Drawing({ fallProtection, height }) {
  return (
    <div className="drawing-container">
      <div className="svg-container">
        {fallProtection ? (
          <Tikas height={height} />
        ) : (
          <Tikas2 height={height} />
        )}
      </div>
    </div>
  );
}

function Configurator() {
  const [dimensions, setDimensions] = useState({ height: 4500 });
  const [material, setMaterial] = useState('anodizedAluminium');
  const [fallProtection, setFallProtection] = useState(false);
  const [topRungsRemoved, setTopRungsRemoved] = useState(0);
  const [rungType, setRungType] = useState('/models/puola.obj');
  const [view, setView] = useState('3D-model'); // Lisätään tila näkymän hallintaan
  const [config, setConfig] = useState({
    dimensions: { height: 4500 },
    material: 'anodizedAluminium',
    count: Math.floor(4500 / STEP) - 1, // Asetetaan count height arvon mukaan ja vähennetään 1
    fallProtection: false,
    topRungsRemoved: 0,
    kiinnityslevyPositions: Array.from({ length: 3 }, (_, i) => -window.innerHeight / 4 + i * ((4500 - 1200) / 2) / 10), // Alustetaan kiinnityslevyjen sijainnit
    rungType: '/models/puola.obj'
  });

  const materials = {
    anodizedAluminium: new MeshStandardMaterial({ color: '#FF4500', metalness: 0.7, roughness: 0.4 }), // Anodized Aluminium
    stainless: new MeshStandardMaterial({ color: '#C0C0C0', metalness: 0.8, roughness: 0.3 }), // Stainless steel AISI 304
    galvanized: new MeshStandardMaterial({ color: '#B0B0B0', metalness: 0.6, roughness: 0.5 }), // Galvanized S235JR + N
    aluminium: new MeshStandardMaterial({ color: '#D3D3D3', metalness: 0.7, roughness: 0.4 }) // Aluminium EN AW-6061
  };

  const handleDimensionChange = (e) => {
    const { name, value } = e.target;
    setDimensions({ ...dimensions, [name]: parseFloat(value) });
  };

  const handleDimensionBlur = () => {
    let height = dimensions.height;
    if (height < 1900) height = 1900;
    if (height > 12000) height = 12000;
    setDimensions({ ...dimensions, height });
  };

  const handleMaterialChange = (e) => {
    setMaterial(e.target.value);
  };

  const handleFallProtectionChange = (e) => {
    setFallProtection(e.target.checked);
  };

  const handleTopRungsRemovedChange = (e) => {
    setTopRungsRemoved(parseInt(e.target.value, 10));
  };

  const handleRungTypeChange = (e) => {
    setRungType(e.target.value);
  };

  const handleApplyChanges = () => {
    let height = dimensions.height;
    if (height < 1900) height = 1900;
    if (height > 12000) height = 12000;

    const kiinnityslevyCount = Math.max(2, 3 + Math.floor((height - 4500) / 1800));
    const totalDistance = (height - 1200) - topRungsRemoved * 300;
    const kiinnityslevyDistance = totalDistance / (kiinnityslevyCount - 1);
    const kiinnityslevyPositions = Array.from({ length: kiinnityslevyCount }, (_, i) => -window.innerHeight / 4 + i * (kiinnityslevyDistance / 10));

    setConfig({
      dimensions: { height },
      material,
      count: Math.floor(height / STEP) - 1 - topRungsRemoved, // Päivitetään count height arvon mukaan ja vähennetään 1 ja topRungsRemoved
      fallProtection,
      topRungsRemoved,
      kiinnityslevyPositions,
      rungType
    });
  };

  const handleViewChange = () => {
    setView((prevView) => (prevView === '3D-model' ? 'Drawing' : '3D-model'));
  };

  const selkasuojaPositions = [];
  for (let i = 0; i < Math.floor((config.dimensions.height - 4000) / SELKASUOJA_HEIGHT) + 2; i++) {
    selkasuojaPositions.push(-window.innerHeight / 4 - (4500 - config.dimensions.height) / 10 - i * (SELKASUOJA_HEIGHT / 10));
  }

  const controlsRef = useRef();

  const handleCenterCamera = () => {
    if (controlsRef.current) {
      controlsRef.current.reset();
    }
  };

  const price = calculatePrice(config); // Lasketaan hinta
  const productCode = generateProductCode(config); // Luodaan tuotekoodi

  return (
    <div className="configurator-container">
      {view === '3D-model' ? (
        <div className="canvas-container">
          <Canvas
            className="canvas"
            camera={{ position: [0, 0, 300], fov: 100 }} // Suurempi FOV ja etäisyys
          >
            {/* Valotuksen parantaminen */}
            <ambientLight intensity={0.4} />
            <directionalLight position={[2, 5, 5]} intensity={1.5} />
            <directionalLight position={[-2, -5, -5]} intensity={0.5} />

            {/* Hallinta zoomauksen sallimiseksi */}
            <OrbitControls ref={controlsRef} enableZoom={true} />

            {/* Pystyputki-malli */}
            <Pystyputki scale={config.dimensions} material={materials[config.material]} />

            {/* Puola-mallit skaalattavaksi ja sijoitettavaksi */}
            <Puola
              material={materials[config.material]}
              count={config.count}
              model={config.rungType}
            />

            {/* Selkäsuoja-mallit */}
            {config.fallProtection && selkasuojaPositions.map((position, index) => (
              <Selkasuoja key={index} material={materials[config.material]} visible={config.fallProtection} position={position} />
            ))}

            {/* Kiinnityslevy-mallit */}
            <Kiinnityslevy material={materials[config.material]} positions={config.kiinnityslevyPositions} />
          </Canvas>
        </div>
      ) : (
        <div className="drawing-container">
          <Drawing fallProtection={config.fallProtection} height={config.dimensions.height} />
        </div>
      )}
      <div className="controls-container">
        <div className="dimension-controls">
          <label>
            Height: 
            <input type="number" name="height" value={dimensions.height} onChange={handleDimensionChange} onBlur={handleDimensionBlur} step="100" />
          </label>
        </div>
        <div className="material-controls">
          <label>
            Material: 
            <select value={material} onChange={handleMaterialChange} style={{ width: '150px' }}>
              <option value="anodizedAluminium">Anodized</option>
              <option value="stainless">AISI 304</option>
              <option value="galvanized">S235JR + N</option>
              <option value="aluminium">EN AW-6061</option>
            </select>
          </label>
        </div>
        <div className="rung-type-controls">
          <label>
            Rung type: 
            <select value={rungType} onChange={handleRungTypeChange} style={{ width: '150px' }}>
              <option value="/models/puola.obj">Plain rung</option>
              <option value="/models/puola2.obj">Anti-slip rung</option>
            </select>
          </label>
        </div>
        <div className="fall-protection-controls">
          <label>
            Fall protection: 
            <input type="checkbox" checked={fallProtection} onChange={handleFallProtectionChange} className="large-checkbox" />
          </label>
        </div>
        <div className="top-rungs-removed-controls">
          <label>
            Top rungs removed: 
            <select value={topRungsRemoved} onChange={handleTopRungsRemovedChange} style={{ width: '75px' }}>
              <option value="0">0</option>
              <option value="1">1</option>
              <option value="2">2</option>
              <option value="3">3</option>
              <option value="4">4</option>
            </select>
          </label>
        </div>
        <div className="buttons-container">
          <button className="ok-button" onClick={handleApplyChanges}>APPLY</button>
          <button className="center-button" onClick={handleCenterCamera}>CENTER</button>
          <button className="view-toggle-button" onClick={handleViewChange}>
            {view === '3D-model' ? 'DRAWING' : '3D-MODEL'}
          </button>
        </div>
        <div className="price-display">
          <label>
            Price: 
            <span>{price} €</span> {/* Näytetään laskettu hinta */}
          </label>
        </div>
        <div className="product-code-display">
          <label>
            Product Code: 
            <span>{productCode}</span> {/* Näytetään tuotekoodi */}
          </label>
        </div>
      </div>
    </div>
  );
}

export default Configurator;

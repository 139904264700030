import React, { useState } from 'react';
import './ContactForm.css';
import * as EmailValidator from 'email-validator'; // Import email-validator
import { useTranslation } from 'react-i18next'; // Import useTranslation

const ContactForm = () => {
  const { t } = useTranslation(); // Initialize useTranslation
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [submitted, setSubmitted] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!EmailValidator.validate(email)) {
      alert(t('contactform.invalid_email')); // Use translation
      return;
    }

    const formData = {
      name,
      email,
      message,
    };

    const response = await fetch('https://api.emailjs.com/api/v1.0/email/send', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        service_id: 'service_ub1bmsb',
        template_id: 'template_6u7bo6c',
        user_id: 'xX_tTE97PZL2xSzNG',
        template_params: {
          name: formData.name,
          email: formData.email,
          message: formData.message,
          to_email: 'info@remodel.fi',
        },
      }),
    });

    if (response.ok) {
      setSubmitted(true);
      setName('');
      setEmail('');
      setMessage('');
    } else {
      alert(t('contactform.error_message')); // Use translation
    }
  };

  return (
    <div className="contact-form-container">
      {submitted ? (
        <div className="thank-you-message">
          <h2>{t('contactform.thank_you')}</h2>
          <p>{t('contactform.thank_you_message')}</p>
        </div>
      ) : (
        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <label htmlFor="name">{t('contactform.name')}</label>
            <input
              type="text"
              id="name"
              value={name}
              onChange={(e) => setName(e.target.value)}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="email">{t('contactform.email')}</label>
            <input
              type="email"
              id="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="message">{t('contactform.message')}</label>
            <textarea
              id="message"
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              required
            ></textarea>
          </div>
          <button type="submit">{t('contactform.submit')}</button>
        </form>
      )}
      <div className="social-links">
        <a href="https://www.facebook.com/remodel.oy/" target="_blank" rel="noopener noreferrer">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
            <path d="M400 32H48A48 48 0 0 0 0 80v352a48 48 0 0 0 48 48h137.3V327.7h-63V256h63v-54.6c0-62.2 37-96.5 93.7-96.5 27.1 0 55.5 4.8 55.5 4.8v61h-31.3c-30.8 0-40.4 19.1-40.4 38.7V256h68.8l-11 71.7h-57.8V480H400a48 48 0 0 0 48-48V80a48 48 0 0 0-48-48z"/>
          </svg>
        </a>
        <a href="https://www.linkedin.com/company/72614776" target="_blank" rel="noopener noreferrer">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
            <path d="M416 32H31.9C14.3 32 0 46.5 0 64.3v383.4C0 465.5 14.3 480 31.9 480H416c17.6 0 32-14.5 32-32.3V64.3c0-17.8-14.4-32.3-32-32.3zM135.4 416H69V202.2h66.5V416zm-33.2-243c-21.3 0-38.5-17.3-38.5-38.5S80.9 96 102.2 96c21.2 0 38.5 17.3 38.5 38.5 0 21.3-17.2 38.5-38.5 38.5zm282.1 243h-66.4V312c0-24.8-.5-56.7-34.5-56.7-34.6 0-39.9 27-39.9 54.9V416h-66.4V202.2h63.7v29.2h.9c8.9-16.8 30.6-34.5 62.9-34.5 67.2 0 79.7 44.3 79.7 101.9V416z"/>
          </svg>
        </a>
      </div>
    </div>
  );
};

export default ContactForm;

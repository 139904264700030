import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation, Navigate } from 'react-router-dom';
import './App.css';
import Header from './components/Header/Header';
import Footer from './components/Footer/Footer';
import LandingPage from './components/LandingPage/LandingPage';
import Services from './components/Services/Services';
import About from './components/About/About';
import ContactForm from './components/ContactForm/ContactForm';
import DesignAutomation from './components/Pages/DesignAutomation';
import MechanicalDesign from './components/Pages/MechanicalDesign';
import ThreeDPrinting from './components/Pages/3DPrinting';
import PrivacyPolicy from './components/Pages/PrivacyPolicy';
import References from './components/Pages/References';
import Bottom from './components/Bottom/Bottom';
import Banner from './components/Banner/Banner';
import Map from './components/Map/Map';
import './i18n';
import { useTranslation } from 'react-i18next';

const GA4_TAG = 'G-6B4CXW0CSW';

const usePageTracking = () => {
  const location = useLocation();
  useEffect(() => {
    if (window.gtag) {
      window.gtag('config', GA4_TAG, {
        page_path: location.pathname,
      });
    }
  }, [location]);
};

const ScrollToTop = () => {
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  return null;
};

const AppContent = () => {
  usePageTracking();
  const { t } = useTranslation();

  return (
    <>
      <ScrollToTop />
      <Header />
      <Routes>
        <Route path="/" element={
          <>
            <LandingPage />
            <Services />
            <div id="about">
              <About />
            </div>
            <div id="contact" className="contact-section">
              <h2 className="contact-header">{t('app.contact_header')}</h2>
              <ContactForm />
            </div>
            <Bottom />
          </>
        } />
        <Route path="/suunnittelun-automatisointi" element={<DesignAutomation />} />
        <Route path="/mekaniikkasuunnittelu" element={<MechanicalDesign />} />
        <Route path="/3d-tulostus" element={<ThreeDPrinting />} />
        <Route path="/tietosuojaseloste" element={<PrivacyPolicy />} />
        <Route path="/referenssit" element={<References />} />
        <Route path="/map" element={<Map />} />
        {/* Catch-all route to handle unknown paths */}
        <Route path="*" element={<Navigate to="/" replace />} />
      </Routes>
      <Footer />
      <Banner />
    </>
  );
};

function App() {
  return (
    <Router>
      <div className="App">
        <AppContent />
      </div>
    </Router>
  );
}

export default App;

import React, { useEffect } from 'react';
import './Footer.css';

const Footer = () => {
  useEffect(() => {
    const lines = document.querySelectorAll('.footer-line');
    const text = document.querySelector('.footer-text');
    const acceptedCookies = sessionStorage.getItem('acceptedCookies');

    if (!acceptedCookies) {
      document.body.classList.add('cookies-accepted-0');
    } else {
      document.body.classList.remove('cookies-accepted-0');
    }

    // Alustava animaatio sivun latauksessa
    lines.forEach(line => {
      line.style.width = '40%';
    });

    setTimeout(() => {
      if (text) {
        text.style.opacity = '1';
      }
    }, 500); // Viiveellä 500ms, jotta se tapahtuu viivojen palautumisen jälkeen

    const handleScroll = () => {
      const scrollPosition = window.scrollY;

      if (scrollPosition > 100) {
        lines.forEach(line => {
          line.style.width = '0';
        });

        setTimeout(() => {
          if (text) {
            text.style.opacity = '0';
          }
        }, 500); // Aikaviive 500ms, joka vastaa CSS-animaation kestoa
      } else {
        lines.forEach(line => {
          line.style.width = '40%'; // Alkuperäinen leveys
        });

        setTimeout(() => {
          if (text) {
            text.style.opacity = '1';
          }
        }, 500); // Viiveellä 500ms, jotta se tapahtuu viivojen palautumisen jälkeen
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <footer className="footer">
      <div className="footer-line-container">
        <div className="footer-line"></div>
        <div className="footer-text">From Ideas to Automation</div>
        <div className="footer-line"></div>
      </div>
    </footer>
  );
};

export default Footer;

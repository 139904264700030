// src/components/Map.js
import React, { useState } from 'react';
import './Map.css';
import { useTranslation } from 'react-i18next'; // Tuodaan useTranslation hook

const Map = () => {
  const [isGrayscale, setIsGrayscale] = useState(true);
  const { t } = useTranslation(); // Käytetään useTranslation hookia käännösten hakemiseen

  const toggleGrayscale = () => {
    setIsGrayscale(!isGrayscale);
  };

  return (
    <div className="map-section">
      <h2 className="map-title">{t('map.title')}</h2>
      <div className="map-container">
        <iframe
          title={t('map.title')}
          className={`map-frame ${isGrayscale ? 'grayscale' : ''}`}
          src="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d951.9357507030024!2d23.765882627812026!3d61.498451901995566!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zNjHCsDI5JzU0LjQiTiAyM8KwNDYnMDAuNSJF!5e0!3m2!1sen!2sfi!4v1720188422467!5m2!1sen!2sfi"
          width="100%"
          height="450"
          style={{ border: 0 }}
          allowFullScreen=""
          loading="lazy"
        ></iframe>
      </div>
      <div className="toggle-button-container">
        <div className={`toggle-button ${isGrayscale ? '' : 'active'}`} onClick={toggleGrayscale}></div>
      </div>
    </div>
  );
};

export default Map;

import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import translationEN from './locales/en.json';
import translationFI from './locales/fi.json';
import translationSE from './locales/se.json';

const resources = {
  en: {
    translation: translationEN
  },
  fi: {
    translation: translationFI
  },
  se: {
    translation: translationSE
  }
};

i18n
  .use(initReactI18next)
  .init({
    resources,
    lng: 'en', // Oletuskieli
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false // React hoitaa escapoinnin
    }
  });

export default i18n;

import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import './DesignAutomation.css';
import demoVideo from '../../images/Automatisointi_Demo.mp4';
import Configurator from '../Configurator/Configurator'; // Tuo Configurator-komponentti

const DesignAutomation = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { t } = useTranslation();
  const [isVideoOpen, setIsVideoOpen] = useState(false);
  const [isFading, setIsFading] = useState(false);

  useEffect(() => {
    const page = document.querySelector('.design-automation');
    if (page) {
      const handleRouteChange = () => {
        page.classList.add('fade-out');
        setTimeout(() => {
          navigate(location.pathname, { replace: true });
        }, 500);
      };

      const links = document.querySelectorAll('a');
      links.forEach(link => {
        link.addEventListener('click', (event) => {
          event.preventDefault();
          handleRouteChange();
          setTimeout(() => {
            navigate(link.getAttribute('href'), { replace: true });
          }, 500);
        });
      });

      return () => {
        links.forEach(link => {
          link.removeEventListener('click', handleRouteChange);
        });
      };
    }
  }, [navigate, location.pathname]);

  const handleVideoClick = () => {
    setIsVideoOpen(true);
  };

  const handleCloseVideo = () => {
    setIsFading(true);
    setTimeout(() => {
      setIsVideoOpen(false);
      setIsFading(false);
    }, 500);
  };

  const scrollToBottom = () => {
    window.scrollTo({
      top: document.documentElement.scrollHeight,
      behavior: 'smooth'
    });
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };

  return (
    <div className="design-automation">
      {isVideoOpen && (
        <div
          className={`video-overlay ${isFading ? 'fade-out' : 'fade-in'}`}
          onClick={handleCloseVideo}
        >
          <video className="expanded-video" controls>
            <source src={demoVideo} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </div>
      )}
      <div className="navigation-arrows">
        <Link to="/mekaniikkasuunnittelu" className="left-arrow">
          〈
          <span className="hover-text left-hover">{t('designAutomation.mechanicalDesign')}</span>
        </Link>
        <h1 className="main-title">{t('designAutomation.title')}</h1>
        <Link to="/3d-tulostus" className="right-arrow">
          〉
          <span className="hover-text right-hover">{t('designAutomation.3dPrinting')}</span>
        </Link>
      </div>
      <hr className="section-divider" />
      <p>{t('designAutomation.improveProcess')}</p>
      <p>{t('designAutomation.similarProducts')}</p>
      <p>{t('designAutomation.automatedDesign')}</p>
      <button className="demo-button" onClick={scrollToBottom}>{t('designAutomation.demo')}</button>
      <hr className="section-divider" />
      <div className="video-wrapper">
        <video
          className="design-video"
          width="480"
          autoPlay
          muted
          loop
          playsInline
          onClick={handleVideoClick}
        >
          <source src={demoVideo} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      </div>

      {/* Lisää konfiguraattori tähän */}
      <div style={{ marginTop: '40px' }}>
        <Configurator />
      </div>
      <button className="scroll-to-top" onClick={scrollToTop}>
        〈
      </button>
    </div>
  );
};

export default DesignAutomation;

import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import './Header.css';

const Header = () => {
  const navigate = useNavigate();
  const [menuOpen, setMenuOpen] = useState(false);
  const [languageMenuOpen, setLanguageMenuOpen] = useState(false);
  const [menuAnimating, setMenuAnimating] = useState(false);
  const { t, i18n } = useTranslation();

  const scrollToSection = (sectionId) => {
    const section = document.getElementById(sectionId);
    if (section) {
      section.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const handleScrollLink = (path, sectionId) => {
    if (window.location.pathname !== path) {
      navigate(path);
      setTimeout(() => scrollToSection(sectionId), 100);
    } else {
      scrollToSection(sectionId);
    }
    setMenuOpen(false);
  };

  const handleAnimationEnd = () => {
    setMenuAnimating(false);
  };

  const handleMenuToggle = () => {
    setMenuAnimating(true);
    setMenuOpen(!menuOpen);
  };

  const changeLanguage = (lang) => {
    i18n.changeLanguage(lang);
    setLanguageMenuOpen(false);
  };

  const closeMenu = () => {
    if (menuOpen) {
      setMenuOpen(false);
    }
  };

  const availableLanguages = [
    { code: 'en', label: 'English', flag: 
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 480">
        <path fill="#012169" d="M0 0h640v480H0z" />
        <path fill="#FFF" d="m75 0 244 181L562 0h78v62L400 241l240 178v61h-80L320 301 81 480H0v-60l239-178L0 64V0z" />
        <path fill="#C8102E" d="m424 281 216 159v40L369 281zm-184 20 6 35L54 480H0zM640 0v3L391 191l2-44L590 0zM0 0l239 176h-60L0 42z" />
        <path fill="#FFF" d="M241 0v480h160V0zM0 160v160h640V160z" />
        <path fill="#C8102E" d="M0 193v96h640v-96zM273 0v480h96V0z" />
    </svg>
    },
    { code: 'fi', label: 'Suomi', flag: 
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 480">
        <path fill="#fff" d="M0 0h640v480H0z" />
        <path fill="#002f6c" d="M0 174.5h640v131H0z" />
        <path fill="#002f6c" d="M175.5 0h130.9v480h-131z" />
    </svg>
    },
    { code: 'se', label: 'Svenska', flag: 
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 480">
        <path fill="#005293" d="M0 0h640v480H0z" />
        <path fill="#fecb00" d="M176 0v192H0v96h176v192h96V288h368v-96H272V0z" />
    </svg>
    }
  ];

  const currentLanguage = i18n.language;
  const otherLanguages = availableLanguages.filter(lang => lang.code !== currentLanguage);

  return (
    <header className="header">
      <div className={`hamburger-menu ${menuOpen ? 'open' : ''}`} onClick={handleMenuToggle}>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 192 512"><path d="M144 80c0 26.5-21.5 48-48 48s-48-21.5-48-48S69.5 32 96 32s48 21.5 48 48zm0 176c0 26.5-21.5 48-48 48s-48-21.5-48-48 21.5-48 48-48 48 21.5 48 48zm-48 224c26.5 0 48-21.5 48-48s-21.5-48-48-48-48 21.5-48 48 21.5 48 48 48z"/></svg>
      </div>
      <div className={`nav-background ${menuOpen ? 'open' : 'closed'}`} onClick={closeMenu}></div>
      <nav>
        <ul
          className={`nav-links ${menuOpen ? 'open' : 'closed'} ${!menuOpen && !menuAnimating ? 'hidden' : ''}`}
          onAnimationEnd={handleAnimationEnd}
        >
          <li
            className="language-button-container"
            onMouseEnter={() => setLanguageMenuOpen(true)}
            onMouseLeave={() => setLanguageMenuOpen(false)}
          >
            <button className="language-button">
              {availableLanguages.find(lang => lang.code === currentLanguage)?.flag}
            </button>
            <ul className={`language-dropdown ${languageMenuOpen ? 'open' : ''}`}>
              {otherLanguages.map(lang => (
                <li key={lang.code} onClick={() => changeLanguage(lang.code)}>
                  {lang.flag}
                </li>
              ))}
            </ul>
          </li>
          <li><Link to="/" onClick={() => setMenuOpen(false)}>{t('header.home')}</Link></li>
          <li>
            <button onClick={() => handleScrollLink('/', 'about')} className="nav-link-button">
              {t('header.about')}
            </button>
          </li>
          <li>
            <button onClick={() => handleScrollLink('/', 'contact')} className="nav-link-button">
              {t('header.contact')}
            </button>
          </li>
          <li><Link to="/referenssit" onClick={() => setMenuOpen(false)}>{t('header.references')}</Link></li>
        </ul>
      </nav>
    </header>
  );
};

export default Header;
